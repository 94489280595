import React from 'react';
import FormattedText from "../components/FormattedText"
import Button from "../components/button"
import { Link } from "gatsby"
// import Img from "gatsby-image";

// const _getImageSize = (primary) => {
//   if(primary.image.dimensions){
//     return {
//       width: primary.image.dimensions.width,
//       height: primary.image.dimensions.height
//     }
//   }
//   return {}
// }

const TwoColBlockList = ({ items, primary}) => {
  return (
    <section className="py-24" style={{background: primary.bg_color, color: primary.text_color}}>
      <div className="container">
        {primary.image.localFile &&
          <figure className="mb-16 mx-auto flex justify-center">
            {/* <Img className="mx-auto" fluid={primary.image.localFile.childImageSharp.fluid} alt={primary.image.alt} /> */}
            <img src={primary.image.url} alt={primary.image.alt} />
          </figure>
        }
        <header className="text-center mb-16 lg:mb-32 lg:w-2/3 mx-auto">
          <div className="mb-4">
            <FormattedText text={primary.title.html} />
          </div>
          <FormattedText text={primary.body_text.html} />
            
            {primary.show_body_icons && 
              <div className="my-8 flex justify-center">
                {items.map((item) => {
                    return (
                      <img className="mx-4 h-4 lg:h-auto" src={item.item_icon.url} alt={item.item_icon.alt} />
                    )
                  })
                }
              </div>
            }
            


          {(primary.cta && !primary.cta.isBroken) &&
            <Link to={`/${primary.cta.slug}`}>
              <Button isDark={primary.bg_color === "#000000"}/>
            </Link>
          }

          
        </header>
        
        <div className="lg:grid grid-cols-2 col-gap-24 row-gap-12 lg:px-32">
          {items.map((item) => {
            return (
              <div className="mb-12 lg:mb-0">
                {primary.show_item_icons &&
                  <img className="h-4 lg:h-auto mb-4 lg:mb-4" src={item.item_icon.url} alt={item.item_icon.alt} />
                }
                
                <FormattedText text={item.item_title.html} />
                <FormattedText text={item.item_text.html} />
              </div>
            )
          })}

        </div>

      </div>
    </section>
  );
};

export default TwoColBlockList;