import React from 'react';
import FormattedText from "../components/FormattedText"
import Button from "../components/button"
import { Link } from "gatsby"
import ReactResizeDetector from 'react-resize-detector';

import BackgroundImage from 'gatsby-background-image'

const _setBGPlace = (primary) => {
  switch(primary.bg_image_place){
    case "UPPER":
      return {
        style: {
          paddingTop: "512px",
        },
        mobile: {
          paddingTop: "50%"
        },
        className: "container bg-top bg-contain"
      }

    case "LOWER":
      return {
        style: {
          paddingBottom: "712px",
          paddingTop: "64px"
        },
        mobile: {
          paddingBottom: "75%",
          paddingTop: "0"
        },
        className: "container bg-bottom bg-contain"
      }

    default:
      return { 
        style: {
          padding: "0"
        },
        className: "container bg-top bg-contain"
      }
  }
}

const WithBackground = ({primary, children}) => {
  if(primary.image.localFile ) {
    return (
      <BackgroundImage
        Tag="section"
        className={_setBGPlace(primary).className}
        fluid={primary.image.localFile.childImageSharp.fluid}
        backgroundColor={`transparent`}
        >

        {/* <ReactResizeDetector handleWidth>
          {({ width }) => <Footer isDesktopOrLaptop={width > 1024} />}
        </ReactResizeDetector> */}

        <ReactResizeDetector handleWidth>
          {({ width }) => (
            <section style={width > 768 ? _setBGPlace(primary).style : _setBGPlace(primary).mobile}>
              {children}
            </section>
          )}
        </ReactResizeDetector>

      </BackgroundImage>
    )
  }
  return (
    <div className="container">
      {children}
    </div>
  )
}

const ThreeColBlockList = ({ items, primary}) => {
  
  return (
    <section className={primary.clip ? "primary-clip pb-24 relative" : "py-24 relative"} style={{
      background: primary.bg_color, 
      color: primary.text_color
      }}>
    
    <WithBackground primary={primary} >
      <>
      
        <div className="lg:flex">
          <header className="mb-16 lg:mb-48 mt-16 lg:w-1/2 lg:pr-16">
            <div className="mb-4 big-title">
              <FormattedText text={primary.title.html} />
            </div>
            
            <FormattedText text={primary.body_text.html} />

            <div className="my-8 flex">
              {items.map((item, key) => {
                  return (
                    <>
                    {item.item_icon.url &&
                      <img key={key} className="h-4 lg:h-auto mr-8" src={item.item_icon.url} alt={item.item_icon.alt} />
                    }
                    </>
                  )
                })
              }
            </div>

            {!primary.cta.isBroken &&
              <Link to={`/${primary.cta.slug}`}>
                <Button isDark={primary.bg_color === "#000000"} />
              </Link>
            }
            
          </header>
          
          
          <div className="flex-1 lg:grid grid-cols-2 col-gap-16">
            {items.map((item, key) => {
              return (
                <div key={key} className="mb-12 lg:mb-0">
                  {item.item_icon.url &&
                    <img style={{filter: "invert(0)"}} className="mb-4 h-4 lg:h-auto" src={item.item_icon.url} alt={item.item_icon.alt} />
                  }
                  <FormattedText text={item.item_title.html} />
                  <FormattedText text={item.item_text.html} />
                </div>
              )
            })}
          </div>
        </div>
      
      </>
    </WithBackground>
  </section>
  );
};

export default ThreeColBlockList;